export const HOME_URI = '/?ptype=homepage';
export const MOBILE_CHECKOUT_URI = '/v2/checkout/';
export const GDPR_INFO = '/gdprdata';
export const SEARCH_URL = '/catalogsearch/result/';
export const AUTH_URI = {
  PATH: '/authorization?authPage=',
  PARAM_KEY: 'authPage',
  LOGIN_OPTIONS: 'login-options',
  MOBILE_NUMBER_VERIFICATION: 'verify-mobile-number',
  SIGN_UP: 'sign-up-mobile',
  LOGIN_VIA_MOBILE: 'login-mobile',
  LOGIN_VIA_EMAIL: 'login-email',
  MERGE_ACCOUNTS: 'merge-account',
  LOGIN_VIA_MOBILE_TO_MY_ORDER: 'login-mobile-orders',
};
export const FITCODE_RESULTS = '/lp-fit-advisor';
export const FITCODE_SURVEY = '/fit-advisor-survey';
export const MY_ORDERS = '/sales/order/history';
export const MY_ORDERS_V2 = '/my/orders';
export const MY_ORDER_SEQ_NO = '/my/orders/<orderId>/item/';
export const MY_ORDER_SEQ_NO_V1 =
  '/sales/order/view/order_id/v2/<orderId>/?ptype=orderDetail&orderId=<orderId>';
export const ADDRESS_BOOK = '/address-book';
export const ALL_REVIEW = '/all-reviews';
export const TERMS_AND_CONDITIONS = '/lp/terms-and-conditions';
export const PRIVACY_POLICY = '/lp/privacy-policy';
export const SOLD_BY_NYKAA_FASHION = '/lp/sold-by-nykaa-fashion?src=app';
export const CHECKOUT_V2_URI = '/v2/checkout/?platform=desktop';
export const CHECKOUT_V2_ADDRESS_URI = '/v2/checkout/address';
export const CHECKOUT_V3_ADDRESS_URI = '/v2/address';
export const MEGA_MENU_V2 = '/mega-menu-shop';
export const MY_ACCOUNT = '/my-account';
export const CHAT_WITH_US = 'chat-with-us';
export const APP_PROMO = '/app-promo';
export const SALE_PAGE = '/offer.html';
export const WISHLIST = '/wishlist';
export const ABOUT_US = '/about-us.html';
export const HELP_CENTER = '/gateway-api/omsApis/helpCenter';
export const OTP_HELP_CENTER = '/help-center/topic/39';
export const BASE_CART_URL = '/v2/checkout';
export const COUPONS_URL = `${BASE_CART_URL}/coupons`;
export const ADDRESS_URL = `${BASE_CART_URL}/address`;
export const ADDRESS_ADD_URL = `${BASE_CART_URL}/address/new`;
export const ADDRESS_LIST_URL = `${BASE_CART_URL}/address/list`;
export const ADDRESS_GUEST_EDIT_URL = `${BASE_CART_URL}/address/guest-edit`;
export const ADDRESS_EDIT_URL = `${BASE_CART_URL}/address/edit`;
export const FREE_SAMPLE_URL = `${BASE_CART_URL}/sample`;
export const APP_DOWNLOAD_SHORT_URL = `https://nykaafashion.onelink.me/0dWZ/tib5jgiy`;
export const APP_STORE_REDIRECT_LINK = `https://nykaafashion.onelink.me/0dWZ/uk672lw5`;
export const PAYMENT_URL = '/checkout/payment?ptype=checkout';
export const AUTH_URL =
  '/authorization?authPage=login-options&redirectURL=<HOST>/v2/checkout/address';
export const GUEST_AUTH_URL = '/authorization?authPage=login-options&route=checkout';

export const GUEST_CHECKOUT_AUTH_URL = `${GUEST_AUTH_URL}&redirectURL=<HOST>/v2/checkout/address`;

//for email disabled flag
export const GUEST_AUTH_URL_MOBILE_ONLY = '/authorization?authPage=login-mobile&route=checkout';
export const HOME_CART_URL = '/?showCart=1';
export const WIDGET_LISTING = '/widget-listing/<PRODUCT_ID>?widget_type=<WIDGET_TYPE>';

// payment new stack
export const NEW_PAYMENT_STACK = '/v2/payment';

// account-delete
export const ACCOUNT_DELETE = '/delete-account';
